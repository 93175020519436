<template>
  <div :style="menuBaseStyle" class="">
    <v-row>
      <v-col cols="4" class="px-6 cursor-pointer hover-bg-gray" :class="{'bg-gray': isOwnInUrl}" @click="navigateToOwn">
        <div class="d-flex align-start justify-center">
          <img src="./../assets/Eigen.png" height="40px" alt="eigen">
        </div>
        <div class="text-blue d-flex align-end justify-center" :class="{'color-accent': isOwnInUrl}">
          Eigen
        </div>
      </v-col>
      <v-col cols="4" class="px-6 cursor-pointer hover-bg-gray" :class="{'bg-gray': isWiseInUrl}" @click="navigateToWise">
        <div class="d-flex align-start justify-center">
          <img src="./../assets/Wijs.png" height="40px" alt="Wijs">
        </div>
        <div class="text-blue d-flex align-end justify-center" :class="{'color-accent': isWiseInUrl}">
          Wijs
        </div>

      </v-col>
      <v-col cols="4" class="px-6 cursor-pointer hover-bg-gray" :class="{'bg-gray': isDevelopmentInUrl}" @click="navigateToDevelopment">
        <div class="d-flex align-start justify-center">
          <img src="./../assets/Ontwikkelen.png" height="40px" alt="Ontwikkelen">
        </div>
        <div class="text-blue d-flex align-end justify-center" :class="{'color-accent': isDevelopmentInUrl}">
          Ontwikkelen
        </div>
      </v-col>
    </v-row>
    <snackbar-text text="Je hebt geen toegang tot deze pagina" bg-color="red" text-color="white" v-model="error.show" :timeout="3000"/>
  </div>
</template>

<script>


import {mapGetters} from "vuex";
import SnackbarText from "@/components/snackbar-text.vue";

export default {
  name: "FooterDashboard",
  components: {SnackbarText},
  data() {
    return {
      menuBaseStyle: {
        "background-color": this.$vuetify.theme.themes.light.white,
        height: "80px",
        "padding-top": "10px",
        position: "fixed",
        bottom: "5px",
        width: "100%",
        "z-index": "1000",
      },
      menuBaseLogoStyle: {
        height: "40px"
      },
      selectedItem: "own",
      error: {
        show: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    isOwnInUrl() {
      return window.location.href.includes("eigen");
    },
    isWiseInUrl() {
      return window.location.href.includes("wijs");
    },
    isDevelopmentInUrl() {
      return window.location.href.includes("ontwikkelen");
    }
  },
  methods: {
    navigateToOwn() {
      this.selectedItem = "own";
      this.$router.push({name: "dashboardOwn"})
    },
    navigateToWise() {
      if (this.user.wise_access) {
        this.selectedItem = "wise";
        this.$router.push({name: "dashboardWise"})
      } else {
        this.error.show = true;
      }
    },
    navigateToDevelopment() {
      if (this.user.development_access) {
        this.selectedItem = "development";
        this.$router.push({name: "dashboardDevelopment"})
      } else {
        this.error.show = true;
      }
    }
  }
};
</script>

<style scoped>
.text-blue {
  color: #004572;
}
</style>
